import {useSelector} from "react-redux";

function MenuHeader() {

    const global_reducer = useSelector(state => state.global)

    function clickToMain(e){
        window.open(global_reducer.main_url)
    }

    return(
        <div className="header-container">
            <div className="header">
                <div className="toggle-button" onClick={clickToMain}>
                    <i className="fas fa-bars"></i>
                    <i className="fas fa-arrow-left"></i>
                </div>
                <div className="search-box">
                    <i className="fas fa-search"></i>
                    <input type="text" placeholder="Search" />
                </div>
            </div>
        </div>
    )

}

export default MenuHeader