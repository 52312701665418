import {useSelector} from "react-redux";
import MenuPen from "./MenuPen";

function MenuContacts() {

    const current_date = new Date();
    const current_Hours = current_date.getHours()
    const current_Minutes = current_date.getMinutes()
    const global_reducer = useSelector(state => state.global)

    function clickToMain(url, e){
        window.open(url)
    }


    return (
        <div className="body-container">
            <div className="chat-list">
                {
                    global_reducer.parsers.map((parser) => {
                        return (
                            <div className="chat-box" id="Msg" key={parser.main_url} onClick={(e) => {
                                clickToMain(parser.main_url, e)
                            }}>
                                <div className="chat-img">
                                    <img src={parser.avatar_url} alt=""/>
                                </div>
                                <div className="chat-details">
                                    <div className="chat-title">
                                        <h3>{parser.name}</h3>
                                        <span>{current_Hours}:{(current_Minutes < 10 ? '0' : '') + current_Minutes}</span>
                                    </div>
                                    <div className="chat-msg">
                                        <div style={{fontWeight: "bold"}}>
                                            {parser.contacts_phrase}
                                        </div>
                                        <span>{parser.new_messages}</span>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>

            <MenuPen />
        </div>
    )
}

export default MenuContacts