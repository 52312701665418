export const defaults = {
    page_title: "Дмитрий Василец - Телеграм.SITES",
    main_url: "https://tracker.dv-telegram.com/tracking202/redirect/go.php?acip=216",
    name: "Дмитрий Василец",
    tag: "@VasiletsDmitriy",
    main_avatar_url: "/img/1.jpg",
    parsers:[
      {
        name: "Дмитрий Василец",
        parser: "VASILETS",
        tag: "@VasiletsDmitriy",
        main_url: "https://tracker.dv-telegram.com/tracking202/redirect/go.php?acip=216",
        avatar_url: "/img/1.jpg",
        contacts_phrase: "Непрочитанные сообщения",
        new_messages: 15
      },
      {
        name: "Держава24",
        parser: "DERZHAVA",
        tag: "@de24one",
        main_url: "https://tracker.dv-telegram.com/tracking202/redirect/go.php?acip=528",
        avatar_url: "/img/2.jpg",
        contacts_phrase: "Непрочитанные сообщения",
        new_messages: 6
      },
    ],
    posts: []
  }
