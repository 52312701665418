import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import {store} from "./store";
import Menu from "./components/Menu";
import Posts from "./components/Posts";


const root_menu = ReactDOM.createRoot(document.getElementById('root-menu'));
root_menu.render(
    <Provider store={store}>
        <Menu />
    </Provider>
)

const posts = ReactDOM.createRoot(document.getElementById('messages'));

posts.render(
    <Provider store={store}>
        <Posts />
    </Provider>
)

reportWebVitals();
