import {useSelector} from "react-redux";

function MenuPen() {

    const global_reducer = useSelector(state => state.global)

    function clickToMain(e){
        window.open(global_reducer.main_url)
    }

    return (
        <div className="pen" onClick={clickToMain}>
            <i className="fas fa-pen"></i>
        </div>
    )

}

export default MenuPen